import React from 'react'

import '../ServePost.css'

import ChevronRightArrow from '../../../assets/ChevronArrow.svg'
import oylive0 from '../../../assets/visual/graphic_oylive/oylive_000.png'
import oylive1 from '../../../assets/visual/graphic_oylive/oylive_001.png'
import oylive2 from '../../../assets/visual/graphic_oylive/oylive_002.png'
import oylive3 from '../../../assets/visual/graphic_oylive/oylive_003.png'
import oylive4 from '../../../assets/visual/graphic_oylive/oylive_004.png'
import oylive5 from '../../../assets/visual/graphic_oylive/oylive_005.png'
import oylive6 from '../../../assets/visual/graphic_oylive/oylive_006.png'
import oylive7 from '../../../assets/visual/graphic_oylive/oylive_007.png'
import oylive8 from '../../../assets/visual/graphic_oylive/oylive_008.png'
import oylive9 from '../../../assets/visual/graphic_oylive/oylive_009.png'
import oylive10 from '../../../assets/visual/graphic_oylive/oylive_010.png'
import oylive11 from '../../../assets/visual/graphic_oylive/oylive_011.png'

function VisualPost7() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={oylive0} alt="" />
            <img className='content-img' src={oylive1} alt="" />
            <img className='content-img' src={oylive2} alt="" />
            <img className='content-img' src={oylive3} alt="" />
            <img className='content-img' src={oylive4} alt="" />
            <img className='content-img' src={oylive5} alt="" />
            <img className='content-img' src={oylive6} alt="" />
            <img className='content-img' src={oylive7} alt="" />
            <img className='content-img' src={oylive8} alt="" />
            <img className='content-img' src={oylive9} alt="" />
            <img className='content-img' src={oylive10} alt="" />
            <img className='content-img' src={oylive11} alt="" />
        </div>
    )
}

export default VisualPost7