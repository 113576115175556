import React from 'react'

import '../ServePost.css'

import ChevronRightArrow from '../../../assets/ChevronArrow.svg'
import cassette0 from '../../../assets/visual/bx_cassette/cassette_000.png'
import cassette1 from '../../../assets/visual/bx_cassette/cassette_001.png'
import cassette2 from '../../../assets/visual/bx_cassette/cassette_002.png'
import cassette3 from '../../../assets/visual/bx_cassette/cassette_003.png'
import cassette4 from '../../../assets/visual/bx_cassette/cassette_004.png'

function VisualPost8() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={cassette0} alt="" />
            <img className='content-img' src={cassette1} alt="" />
            <img className='content-img' src={cassette2} alt="" />
            <img className='content-img' src={cassette3} alt="" />
            <img className='content-img' src={cassette4} alt="" />
        </div>
    )
}

export default VisualPost8